@import "../variables.module";

.custom-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 290px;
  height: 44px;
  z-index: 5;
  background: radial-gradient(
    ellipse farthest-corner at 50% 100%,
    #262c51,
    #1d2341
  );
  padding: 7px 14px;
  border-radius: 6px;
  border: 1px solid #282f58;
  font-weight: 500;
  cursor: pointer;
  .label-container {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    left: 14px;
    width: calc(100% - 60px);
  }
  .dropdown-icon {
    display: flex;
    align-items: center;
  }

  .first-label {
    font-size: 13px;
    color: rgba(232, 229, 255, 0.75);
    white-space: nowrap;
    cursor: pointer;
    pointer-events: none;
  }

  .second-label {
    font-size: 13px;
    color: rgba(232, 229, 255, 0.75);
    white-space: nowrap;
    cursor: pointer;
    pointer-events: none;
  }

  .first-dropdown {
    width: 100%;
    background: transparent;
    border: none;
    color: $white;
    font-size: 13px;
    padding: 7px 5px;
    outline: none;
    appearance: none;
    -webkit-appearance: none; /* Safari and Chrome */
    cursor: pointer;
    padding-left: 65px;

    option {
      background-color: #1d2341;
      color: $white;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  .second-dropdown {
    background: transparent;
    border: none;
    color: $white;
    font-size: 13px;
    cursor: pointer;
    overflow: hidden;
    label {
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      margin-top: 3px;
      width: 100%;
    }
    z-index: 1;
  }
  .second-dropdown-options {
    position: absolute;
    margin-top: 17px;
    background-color: #262c51;
    // border: 1px solid #FFFFFF22;
    border-radius: 6px;
    width: calc(100% + 60px);
    left: -15px;
    z-index: 10;
    label {
      display: block;
      padding: 14px 24px;
      margin-top: 0;
      border: 1px  solid rgba(124, 131, 177, 0.1);
      &:hover {
        background: linear-gradient(rgba(124, 131, 177, 0.1), rgba(98, 107, 162, 0.1));
      }
    }
    :first-child {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }
    :last-child {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .second-dropdown-pt {
    width: 100%;
    background: transparent;
    border: none;
    color: $white;
    font-size: 13px;
    padding: 7px 5px;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    padding-left: 35px;

    option {
      background-color: #1d2341;
      color: $white;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  .select-box {
    width: 100%;
    background: transparent;
    border: none;
    color: $white;
    font-size: 13px;
    padding: 7px 5px;
    outline: none;
    appearance: none;
    -webkit-appearance: none; /* Safari and Chrome */
    cursor: pointer;
    // padding-left: 40px; /* Adjust padding to make space for the label */

    &.first-dropdown {
      padding-left: 45px;
    }
    &.second-dropdown {
      padding-left: 65px;
    }

    option {
      background-color: #1d2341;
      color: $white;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }
}


@media only screen and (max-width: 1280px) {
  .custom-dropdown {
    width: 100%;
    max-width: 100%;

    .dropdown-label {
      font-size: 13px;
      color: rgba(232, 229, 255, 0.75);
      font-weight: 500;
      white-space: nowrap;
    }
  }
}
