@import "../variables.module";

.custom-scrollbar-container {
    background: inherit;
    position: relative;
    height: 100%;
    border-radius: 6px;
    .custom-scrollbar-content {
        position: relative;
        width: 100%;
        background-color: inherit;
        overflow: auto;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        &::-webkit-scrollbar {
            display: none;
        }
    }    
    
    &:hover {
        .custom-scrollbar-scrollbar {
           opacity: 1;
        }
    }
    .custom-scrollbar-scrollbar {
        height: calc(100% - 50px);
        position: absolute;
        top: 9.5px;
        right: 8px;
        width: 4px;
        transition: opacity 0.8s ease, transform 0.8s ease;
        opacity: 0;
        .custom-scrollbar-track-and-thumb {
            width: 16px;
            height: 100%;
            position: relative;
            
            .custom-scrollbar-track {
                background-color: rgba($color: #7C83B1, $alpha: 0.3);
                border-radius: 12px;
                bottom: 0;
                cursor: pointer;
                position: absolute;
                top: 0;
                width: 4px;
            }
            .custom-scrollbar-thumb {
                background-color: #9DA2C6;
                position: absolute;
                width: 4px;
                height: 25px;
                border-radius: 40px;
            }
        }
    }
}
